#vertical-navigation {
    position: fixed;
    top: 55%;
    bottom: auto;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
}

#vertical-navigation ul {
    list-style-type: none;
    padding-left: 1.5rem;
}

#vertical-navigation li {
    text-align: left;
}

#vertical-navigation a {
    display: inline-block;
    /* prevent weird movements on hover when you use a CSS3 transformation - webkit browsers */
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    outline: 0;
}

#vertical-navigation a:hover {
    cursor: pointer;
}

#vertical-navigation a:after {
    content: "";
    display: table;
    clear: both;
}

#vertical-navigation a span {
    float: left;
    display: inline-block;
    -webkit-transform: scale(0.6);
    -moz-transform: scale(0.6);
    -ms-transform: scale(0.6);
    -o-transform: scale(0.6);
    transform: scale(0.6);
}

#vertical-navigation a:hover span {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}

#vertical-navigation a:hover .vertical-navigation-label {
    opacity: 1;
}

#vertical-navigation .vertical-navigation-dot {
    position: relative;
    /* we set a top value in order to align the dot with the label. If you change label's font, you may need to change this top value*/
    top: 8px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: white;
    -webkit-transition: -webkit-transform 0.2s, background-color 0.5s;
    -moz-transition: -moz-transform 0.2s, background-color 0.5s;
    transition: transform 0.2s, background-color 0.5s;
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
}

#vertical-navigation .vertical-navigation-label {
    position: relative;
    margin-left: 5px;
    padding: .5em .5em;
    color: white;
    font-size: 0.875rem;
    -webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
    -moz-transition: -moz-transform 0.2s, opacity 0.2s;
    transition: transform 0.2s, opacity 0.2s;
    opacity: 0;
    -webkit-transform-origin: 0% 50%;
    -moz-transform-origin: 0% 50%;
    -ms-transform-origin: 0% 50%;
    -o-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
}

@media all and (max-width: 960px) {
    #vertical-navigation {
        display: none;
    }
}
