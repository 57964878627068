.about-me {
    padding: 5rem 10rem !important;
}

.skillbar-container {
    margin-bottom: 2rem;
}

.skillbar {
    margin-bottom: 1.5rem;
}

.skillbar-title {
    color: white;
    margin-bottom: 0.25rem;
    display: block;
}

.progress-bar {
    transition: width 2s ease;
}

.badges-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.about-me-badge {
    height: 10rem;
    width: 10rem;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 1rem;
    margin-bottom: 1rem;
    opacity: 0;
    transition: opacity 2s, transform 0.15s;
}

.about-me-badge:hover {
  transform: scale(1.2);
  transition: 0.15s;
}

@media all and (max-width: 1200px) {
}

@media all and (min-width: 961px) and (max-width: 1083px) {
    .badges-container {
        justify-content: space-around;
    }
}

@media all and (max-width: 960px) {
  .about-me {
    padding: 2rem 1rem !important;
  }
}

@media all and (max-width: 768px) {
  .about-last {
    margin-bottom: 2rem;
  }
}

@media all and (max-width: 500px) {
    .badges-container {
        justify-content: space-around;
    }

    .about-me-badge {
        margin-right: 0;
    }
}

@media all and (min-width: 796px) and (max-width: 900px) {
    .badges-container {
        justify-content: space-between;
    }

    .about-me-badge {
        margin-right: 0;
    }
}

@media all and (min-width: 768px) and (max-width: 796px) {
    .badges-container {
        justify-content: space-around;
    }
}
