.App {
  font-family: 'Poppins', sans-serif;
  background-color: #282c34;
}

@font-face {
  font-family: 'Hearth Stone';
  src: url('./assets/fonts/Hearth Stone.otf');
}

.app-section-title {
    color: white;
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 2rem;
}

.app-section-body-text {
    color: rgba(255,255,255,0.6);
    line-height: 1.75rem;
}

.section-divider {
    background-color: rgba(255,255,255,0.6);
    margin: 0 10.8rem 0 10.8rem;
}

@media all and (max-width: 1200px) {
}

@media all and (max-width: 960px) {
  .section-divider {
    margin: 0 2rem 0 2rem !important;
  }
}

@media all and (max-width: 768px) {
}
