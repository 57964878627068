.nav-logo {
  font-family: 'Hearth Stone';
  font-size: 25px;
  font-weight: 600;
}

.navbar-brand {
  padding-bottom: 0.5rem;
}

.nav-social-media {
  padding: 0.5rem 0 0.5rem 0 !important;
}

.nav-icon {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  transition: 0.15s;
}

.nav-icon:hover {
  transform: scale(1.5);
  transition: 0.15s;
}

.navbar {
  padding: 0.25rem 1rem !important;
}

.nav-text {
  color: white !important;
  font-weight: 500 !important;
  font-size: 15px !important;
}

.nav-text:hover {
  color: rgb(255, 255, 255, 0.5) !important;
}

@media all and (max-width: 1200px) {
}

@media all and (max-width: 960px) {
  .nav-social-media a:first-child img {
    margin-left: 0;
  }

  .navbar {
    padding: 0.5rem 1rem !important;
  }
}

@media all and (max-width: 768px) {
}
