body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  font-family: 'Poppins', sans-serif;
  background-color: #282c34;
}

@font-face {
  font-family: 'Hearth Stone';
  src: url("/static/media/Hearth Stone.a829eb03.otf");
}

.app-section-title {
    color: white;
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 2rem;
}

.app-section-body-text {
    color: rgba(255,255,255,0.6);
    line-height: 1.75rem;
}

.section-divider {
    background-color: rgba(255,255,255,0.6);
    margin: 0 10.8rem 0 10.8rem;
}

@media all and (max-width: 1200px) {
}

@media all and (max-width: 960px) {
  .section-divider {
    margin: 0 2rem 0 2rem !important;
  }
}

@media all and (max-width: 768px) {
}

.nav-logo {
  font-family: 'Hearth Stone';
  font-size: 25px;
  font-weight: 600;
}

.navbar-brand {
  padding-bottom: 0.5rem;
}

.nav-social-media {
  padding: 0.5rem 0 0.5rem 0 !important;
}

.nav-icon {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  transition: 0.15s;
}

.nav-icon:hover {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
  transition: 0.15s;
}

.navbar {
  padding: 0.25rem 1rem !important;
}

.nav-text {
  color: white !important;
  font-weight: 500 !important;
  font-size: 15px !important;
}

.nav-text:hover {
  color: rgb(255, 255, 255, 0.5) !important;
}

@media all and (max-width: 1200px) {
}

@media all and (max-width: 960px) {
  .nav-social-media a:first-child img {
    margin-left: 0;
  }

  .navbar {
    padding: 0.5rem 1rem !important;
  }
}

@media all and (max-width: 768px) {
}

#vertical-navigation {
    position: fixed;
    top: 55%;
    bottom: auto;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
}

#vertical-navigation ul {
    list-style-type: none;
    padding-left: 1.5rem;
}

#vertical-navigation li {
    text-align: left;
}

#vertical-navigation a {
    display: inline-block;
    /* prevent weird movements on hover when you use a CSS3 transformation - webkit browsers */
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    outline: 0;
}

#vertical-navigation a:hover {
    cursor: pointer;
}

#vertical-navigation a:after {
    content: "";
    display: table;
    clear: both;
}

#vertical-navigation a span {
    float: left;
    display: inline-block;
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
}

#vertical-navigation a:hover span {
    -webkit-transform: scale(1);
    transform: scale(1);
}

#vertical-navigation a:hover .vertical-navigation-label {
    opacity: 1;
}

#vertical-navigation .vertical-navigation-dot {
    position: relative;
    /* we set a top value in order to align the dot with the label. If you change label's font, you may need to change this top value*/
    top: 8px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: white;
    transition: background-color 0.5s, -webkit-transform 0.2s;
    transition: transform 0.2s, background-color 0.5s;
    transition: transform 0.2s, background-color 0.5s, -webkit-transform 0.2s;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
}

#vertical-navigation .vertical-navigation-label {
    position: relative;
    margin-left: 5px;
    padding: .5em .5em;
    color: white;
    font-size: 0.875rem;
    transition: opacity 0.2s, -webkit-transform 0.2s;
    transition: transform 0.2s, opacity 0.2s;
    transition: transform 0.2s, opacity 0.2s, -webkit-transform 0.2s;
    opacity: 0;
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
}

@media all and (max-width: 960px) {
    #vertical-navigation {
        display: none;
    }
}

.page-footer {
    background-color: #000410 !important;
    padding: 7.5rem 0 7.5rem 0;
}

.page-footer-title h3 {
    color: white;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 1.5rem;
}

.page-footer-text {
    color: #777;
}

.copyright {
    margin-top: 1rem;
    font-style: italic;
}

footer .container-fluid {
    max-width: 1170px;
}

.page-footer-icons {
    display: flex;
    margin-bottom: 1rem;
    justify-content: flex-start;
}

.footer-icon {
    height: 2.5rem;
    width: 2.5rem;
    margin-right: 1rem;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

@media all and (max-width: 1200px) {
}

@media all and (max-width: 960px) {
}

@media all and (max-width: 768px) {
    .page-footer {
        padding: 4.5rem 0 4.5rem 0 !important;
    }

    .page-footer-col1 {
        margin-bottom: 1rem;
    }
}

.main-hero {
    background: white;
}

.main-hero-col1 {
    padding: 0 !important;
    height: 95vh !important;
}

canvas {
    height: 95vh !important;
    background-attachment: fixed;
    background-position: 50% 30%;
    background-repeat: no-repeat;
    background-size: cover;
}

.main-hero-col2 {
    height: 95vh;
    position: absolute;
    display: flex;
    align-items: center;
    background: rgba(40,44,52,0.75);
    -webkit-transform: translateY(-750px);
            transform: translateY(-750px);
    opacity: 0;
    transition: 2s;
}

.main-hero-subtitle {
    font-size: 30px;
    font-weight: 100;
    color: white;
}

.main-hero-title {
    font-family: 'Hearth Stone';
    font-size: 55.5px;
    font-weight: 600;
    margin-bottom: 2.25rem;
    color: khaki;
}

.main-hero-text {
    line-height: 25px;
    margin-bottom: 2rem;
    color: rgba(255,255,255,0.6);
}

.main-hero-text-container {
    padding: 0.5rem;
}

.hero-subtitle2 {
    margin-bottom: 2rem;
}

.hero-subtitle1 {
    margin-bottom: 0.8rem;
}

.main-hero-button {
    border-radius: 30px;
    border: 2px solid #007bff;
}

.hero-row {
    justify-content: flex-end;
}

.hero-emphasis {
    color: white;
    font-weight: 600;
}

@media all and (max-width: 1200px) {
}

@media all and (max-width: 960px) {
}

@media all and (max-width: 767px) {
    .hero-subtitle1 {
        margin-top: 2rem;
    }

    .hero-subtitle2 {
        margin-top: 1rem;
    }

    .main-hero-button {
        margin-bottom: 2.5rem;
    }

    .main-hero-col2 {
        background: rgba(40,44,52,0.5);
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }

    canvas {
        background-attachment: scroll;
    }

    .main-hero-title {
        margin-top: 2rem;
    }

    .main-hero-text {
        color: rgba(255,255,255,0.6);
    }
}

@media all and (max-height: 750px) and (max-width: 400px) {
    canvas, .main-hero-col1, .main-hero-col2 {
        height: 100vh !important;
    }

    .hero-subtitle1 {
        margin-top: 2rem !important;
    }
}

@media all and (max-width: 321px) {
    canvas, .main-hero-col1, .main-hero-col2 {
        height: 135vh !important;
    }

    .hero-subtitle1 {
        margin-top: 1.75rem !important;
    }
}

/* Landscape orientation */

@media all and (max-height: 425px) and (min-width: 550px) {
    canvas, .main-hero-col1, .main-hero-col2 {
        height: 100vw !important;
    }

    canvas {
        background-attachment: scroll;
    }
}

/* Tablets */

@media all and (min-height: 1000px) {
    canvas, .main-hero-col1, .main-hero-col2 {
        height: 85vh !important;
    }
}

@media all and (min-height: 1280px) {
    canvas, .main-hero-col1, .main-hero-col2 {
        height: 67.5vh !important;
    }
}

.about-me {
    padding: 5rem 10rem !important;
}

.skillbar-container {
    margin-bottom: 2rem;
}

.skillbar {
    margin-bottom: 1.5rem;
}

.skillbar-title {
    color: white;
    margin-bottom: 0.25rem;
    display: block;
}

.progress-bar {
    transition: width 2s ease;
}

.badges-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.about-me-badge {
    height: 10rem;
    width: 10rem;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 1rem;
    margin-bottom: 1rem;
    opacity: 0;
    transition: opacity 2s, -webkit-transform 0.15s;
    transition: opacity 2s, transform 0.15s;
    transition: opacity 2s, transform 0.15s, -webkit-transform 0.15s;
}

.about-me-badge:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  transition: 0.15s;
}

@media all and (max-width: 1200px) {
}

@media all and (min-width: 961px) and (max-width: 1083px) {
    .badges-container {
        justify-content: space-around;
    }
}

@media all and (max-width: 960px) {
  .about-me {
    padding: 2rem 1rem !important;
  }
}

@media all and (max-width: 768px) {
  .about-last {
    margin-bottom: 2rem;
  }
}

@media all and (max-width: 500px) {
    .badges-container {
        justify-content: space-around;
    }

    .about-me-badge {
        margin-right: 0;
    }
}

@media all and (min-width: 796px) and (max-width: 900px) {
    .badges-container {
        justify-content: space-between;
    }

    .about-me-badge {
        margin-right: 0;
    }
}

@media all and (min-width: 768px) and (max-width: 796px) {
    .badges-container {
        justify-content: space-around;
    }
}

.experience {
    padding: 5rem 10rem !important;
}

.work-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.work-card {
    width: 22rem;
    border: 2px solid grey;
    border-radius: 5px;
    margin-bottom: 1.5rem;
}

.card-image {
    background-color: white;
    height: 12.5rem;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 5px;
}

.card-body {
    background-color: #282c34;
    border-radius: 0 0 2px 2px;
}

.card-title {
    font-weight: 600;
    margin-bottom: 1rem;
    color: white;
}

.card-subtitle {
    margin-bottom: 0.5rem;
    color: rgba(255,255,255,0.75);
}

@media all and (max-width: 1200px) {
}

@media all and (min-width: 961px) and (max-width: 1053px) {
    .work-cards-container {
        justify-content: space-around;
    }
}

@media all and (max-width: 960px) {
    .experience {
        padding: 2rem 1rem !important;
    }
}

@media all and (max-width: 767px) {
    .work-cards-container {
        justify-content: space-around;
    }
}

